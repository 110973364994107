<template>
    <v-container class="mb-3">
        <AdBTWR v-if="!user.subscribed"/>

        <div class="mt-5">
            <v-alert type="success" v-if="messageSent">
                Your message has been sent. Someone will respond to your message as soon as possible.
            </v-alert>
            <v-alert type="error" v-if="messageError">
                An error has occurred while sending your message: {{ messageError }}
            </v-alert>
        </div>

        <v-row justify="center">
            <v-col cols="12" sm="10" md="6">
                <v-form ref="form" v-model="valid" @submit.prevent="sendMessage()">
                    <v-card class="mb-5 elevation-15">
                        <v-card-title>
                            <h2 class="page-header font-weight-light">Contact Us</h2>
                        </v-card-title>
                        <v-card-text>

                            <v-alert
                                type="warning"
                                class="mb-5"
                            >
                                Please note that it can take 24-48 hours after the "Grant Date" shown on your GMRS
                                license before it shows up on our website. The FCC publishes daily updates to
                                third-party websites, however they are usually at least one day behind.
                            </v-alert>

                            <div
                                :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-space-between mb-5' : 'text-center mb-5'">
                                <span class="title font-weight-light mr-3">
                                    <v-icon large color="info" class="mr-1">mdi-comment-question</v-icon>
                                    Check if your question has been answered on our FAQs page:
                                </span>
                                <v-btn color="info" href="https://help.mygmrs.com/2466-help-center/5258-faq">
                                    Visit FAQs
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </div>

<!--                            <div class="text-center ma-5">-->
<!--                                <p class="mb-2">Have an issue with the new website?<br>Report bugs by clicking the-->
<!--                                    button below:</p>-->
<!--                                <v-btn-->
<!--                                    dark-->
<!--                                    color="purple"-->
<!--                                    href="https://docs.google.com/forms/d/e/1FAIpQLSe6XLmCW-UU0Bp9lN9zM3L9gO_TCkArIN2oY-hOLG9x01B3lg/viewform"-->
<!--                                    target="_blank"-->
<!--                                >-->
<!--                                    <v-icon class="mr-1" size="20">mdi-emoticon-excited</v-icon>-->
<!--                                    Feedback-->
<!--                                    <v-icon class="ml-1" size="20">mdi-emoticon-angry</v-icon>-->
<!--                                </v-btn>-->
<!--                            </div>-->

                            <v-text-field
                                v-model="message.name"
                                label="Name"
                                :rules="nameRules"
                                outlined
                                required
                                counter
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="message.email"
                                label="Email Address"
                                :rules="emailRules"
                                outlined
                                required
                            >
                            </v-text-field>
                            <v-textarea
                                v-model="message.body"
                                label="Message"
                                :rules="bodyRules"
                                outlined
                                required
                                counter
                            ></v-textarea>

                            <vue-recaptcha
                                ref="recaptcha"
                                :sitekey="sitekey"
                                :loadRecaptchaScript="true"
                                @verify="onVerify"
                                @expired="onExpired"
                                @error="onError"
                                class="d-flex justify-center"
                            >
                            </vue-recaptcha>
                            <!--<div class="text-center">-->
                            <!--<button @click="resetRecaptcha">Reset ReCAPTCHA</button>-->
                            <!--</div>-->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid">
                                Send Message
                                <v-icon small class="ml-1">mdi-send</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import config from '../config';
    import axios from 'axios';
    import VueRecaptcha from 'vue-recaptcha';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        components: {VueRecaptcha, AdBTWR},

        props: ['user'],

        data: () => ({
            sitekey: '6LfcLuEUAAAAAIMcWeWwBPtwe9QMLb3twk3H0sBX',
            message: {
                name: null,
                email: null,
                body: null,
            },
            responseToken: null,
            valid: false,
            loading: false,
            messageSent: false,
            messageError: null,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 30) || 'Name must be less than 30 characters',
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            bodyRules: [
                v => !!v || 'Message is required',
                v => (v && v.length <= 1000) || 'Message must be less than 1000 characters',
            ],
        }),
        methods: {
            onVerify(response) {
                this.responseToken = response;
            },

            onExpired() {
                console.log('Expired');
            },

            onError(err) {
                console.error(err);
            },

            resetRecaptcha() {
                this.$refs.recaptcha.reset();
            },

            sendMessage() {
                this.messageSent = false;

                if (this.valid) {
                    this.messageError = null;
                    this.loading = true;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    axios.post(config.API_LOCATION + '/contact', {
                        name: this.message.name,
                        email: this.message.email,
                        message: this.message.body,
                        token: this.responseToken
                    }, {
                        headers: axiosHeaders
                    })
                        .then(response => {
                            this.loading = false;
                            if (response.data.success) {
                                this.messageSent = true;
                                this.clearForm();
                                this.resetRecaptcha();
                            } else {
                                console.error(response.data);
                                this.messageError = response.data.message;
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            this.messageError = err;
                        });
                }
            },

            clearForm() {
                this.$refs.form.reset();

                this.responseToken = null;
            }
        },
        mounted() {
            useTitle('Contact Us - myGMRS.com');

            if (this.user) {
                this.message.name = this.user.firstName ? `${this.user.firstName} ${this.user.lastName}` : this.user.username;
                this.message.email = this.user.email;
            }
        }
    }
</script>
